import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'

function Hero() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // animation duration in milliseconds
    });
  }, []);

  return (
    <div
      className="relative flex items-center justify-center h-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url(https://res.cloudinary.com/dregpcfhh/image/upload/v1720154178/Nivez_Bridal/Homepage/hero-1_kl0psn.jpg)`,
        lazy: 'true',
      }}
    >
      <div className="absolute inset-0 bg-primary bg-opacity-50"></div>
      <div className="relative text-center z-10 p-4" data-aos="fade-up">
        <h1
          className="text-2xl font-thin text-white"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          Experience Luxury and Elegance at
        </h1>
        <h1
          className="text-7xl shine-text text-white font-jomhuria"
          data-aos="fade-up"
          data-aos-delay="400"
        >
          Nivez Bridal & Academy
        </h1>

        <button
          onClick={() => {
            window.location.href = '/#about-us';
          }}
          data-aos="fade-up"
          data-aos-delay="600"
          className="mt-6 px-4 py-2 sm:px-6 sm:py-3 bg-secondary text-primary font-semibold rounded-md hover:bg-yellow-500/80 transition duration-300 animate-bounce"
        >
          Discover more
        </button>
      </div>
    </div>
  );
}

export default Hero;
