import React from 'react';
import Header from '../components/header/Header';
import { Footer } from '../components/footer';
import ElfsightWidget from '../components/ElfsightWidget';

export default function GalleryPage() {
  return (
    <div>
      <Header />
      <div className="w-full">
        <div className="text-center">
          <h1 className="text-7xl font-bold font-gwendolyn bg-primary/80 text-secondary py-10">
            Gallery
          </h1>
          <div className="bg-white text-black py-5 px-6 rounded-lg max-w-3xl mx-auto">
            <h3 className="text-4xl lg:text-5xl font-galdeano text-primary my-4 lg:mt-8">
              Our Instagram Collection
            </h3>
            <p className="text-md text-gray-700">
              Explore our vibrant Instagram Collection, showcasing the latest
              styles and trends. Dive into our curated gallery for inspiration
              and discover how to elevate your look!
            </p>
          </div>
        </div>
        <ElfsightWidget />
      </div>
      <Footer />
    </div>
  );
}
