import React, { useEffect } from 'react';

const ElfsightWidget = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div
      className="elfsight-app-a04fb100-d206-48e9-947d-287aa267e67a md:px-10 px-2"
      data-elfsight-app-lazy
    ></div>
  );
};

export default ElfsightWidget;
